<template>
    <component
        :is="tag"
        style="width: 46px;"
        @click.prevent="click"
    >
        <b-spinner
            v-if="loading"
            small
        />
        <slot v-else />
    </component>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            required: true,
        },
        itemId: {
            type: [String, Number],
            required: true,
        },
        method: {
            type: String,
            required: true,
        },
        successMessage: {
            type: String,
            default: "",
        },
        noAlertDismiss: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        click() {
            this.loading = true;

            this.jsonRpc(this.method, {id: this.itemId}, this.noAlertDismiss).then(async (response) => {
                if (this.successMessage) {
                    this.$alertify.success(this.successMessage);
                }
                
                this.$emit("complete", this.noAlertDismiss ? response.result : response);
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },
    },
}
</script>
