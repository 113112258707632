<template>
    <b-button
        :disabled="disabled || loading"
        :href="href"
        :size="size"
        :tag="tag"
        :target="target"
        :to="to"
        :variant="variant"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot v-if="!loading" />
        <b-spinner
            v-else
            small
            class="align-middle"
        />
    </b-button>
</template>

<script>
export default {
    name: "Button",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        href: {
            type: [String, undefined],
            default: undefined,
        },
        size: {
            type: String,
            default: "md",
        },
        tag: {
            type: String,
            default: "button",
        },
        target: {
            type: String,
            default: "_self",
        },
        to: {
            type: [Object, String, undefined],
            default: undefined,
        },
        variant: {
            type: String,
            default: "secondary",
        },
    },
}
</script>